import React from 'react';
import SEO from '../../components/SEO';
import Layout from '../../layouts/index';
//import linkedin from '../../images/Linkedin icon Team_2.svg';
/*
let team = [
  { name: 'Gregory J Martin', position: 'President and CEO', linkedin: 'gregory-j-martin-570791163' },
  { name: 'Frank Alosio', position: 'Chief Technology Officer', linkedin: 'frank-aloisio' },
  { name: 'Charlotte Bradshaw', position: 'Chief Operating Officer', linkedin: 'charlotte-bradshaw-173797168' },
  { name: 'Alfred Wu', position: 'Senior Vice President Asia', linkedin: 'alfred-wu-a6334721' },
  { name: 'Dr. Norrizan Razali', position: 'Malaysian Education Advisor', linkedin: 'drnorrizanrazali' },
  { name: 'Carel Fourie', position: 'Vice President Global Ecosystem', linkedin: 'carel-fourie-9b6b305a' },
  { name: 'Andrew Martin', position: 'Director US Operations', linkedin: 'andrew-martin-75392855' },
  { name: 'Lalit Gupta', position: 'Vice President and Founder India and ASEAN', linkedin: 'lalit-gupta-980297' },
  { name: 'Emer Timmons', position: 'Executive Advisor', linkedin: '' },
  { name: 'Ron Mance', position: 'Vice President Edduus Institute', linkedin: '' },
  { name: 'Wayne England', position: 'Vice President Africa', linkedin: 'wayne-england-09b84364' },
  { name: 'Nahal A. Nellis, Esq', position: 'Legal Counsel', linkedin: 'nahalnellis' },
]
*/

const ContactUs = props => (
  <Layout>
    <SEO title="Contact Us" />
    <section className="contactus" id="contactus">
      <div className="contactus-content">
        <div className="contactus-content-title">
          <p>CONTACT US</p>
        </div>
        <div className="contactus-content-header">
          <p>Launching in 2022, contact us for details</p>
        </div>
        <div className="contactus-content-body">
          <p>If you are in a club or association and want to enjoy the benefits of Edduus then please contact us and we will help you get started on a simple but highly rewarding journey.</p>
        <div className="contactus-content-body-center">
          <p>email: <a className="contactus-content-anchor" href="mailto:support@edduus.com">support@edduus.com</a></p>
          <p>&nbsp;</p>
		</div>
        </div>
      </div>
    </section>
  </Layout>
);

export default ContactUs;

/* contact team
        <div className="contactus-content-team">
          <div className="container">
            <div className="row">
              {team.map((memeber, i)=> (
                <div className="col-12 col-md-4 m-auto" key={i}>
                  <div className="team-member">
                    <p>{memeber.name}</p>
                    <p className="position">{memeber.position}</p>
                    <a href={`https://www.linkedin.com/in/${memeber.linkedin}/`} target="blank">
                      <img alt="LinkedIn Logo" src={linkedin} />
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
*/

